// TODO
// TESTING 

export class RomaAPIError extends Error {
  status: number;
  statusText: string;
  code: string;
  apiMessage: string;
  response: Response;

  constructor(response: Response, data: { Code: string; Message: string }) {
    super(
      `${response.status} ${response.statusText} - ${data.Code}: ${data.Message}`
    );

    this.name = "RomaAPIError";

    // Original status/statusText/entire response 
    this.status = response.status;
    this.statusText = response.statusText;
    this.response = response;

    // Code and Message returned from Roma api..
    this.code = data.Code;
    this.apiMessage = data.Message;

    Object.setPrototypeOf(this, RomaAPIError.prototype);
  }
}