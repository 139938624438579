import { Component, Show, createSignal } from "solid-js";
import { useSessionContext } from "~/utils/contexts";
import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import * as z from "zod";
import { AccountPanel } from "~/components/account";
import { TextFieldInput } from "~/components/inputs";
import { Loader } from "~/components/utility";
import Button from "~/components/Button";
import { eyeSlash } from "solid-heroicons/solid";
import { RomaAPIError } from "~/services/roma-api/RomaAPIError";

const Password: Component = () => {
  const { session } = useSessionContext();
  const [formState, setFormState] = createSignal<{
    status: "success" | "error" | null;
    message: string;
  }>({ status: null, message: "" });

  const nonEmpty = z
    .string()
    .trim()
    .min(1, { message: "Field cannot be empty" });

  const schema = z
    .object({
      current_password: nonEmpty,
      password: nonEmpty,
      confirm_password: nonEmpty,
    })
    .refine(({ password, confirm_password }) => password === confirm_password, {
      message: "Passwords must match",
      path: ["confirm_password"],
    });

  const { form, isSubmitting, errors, data, reset } = createForm<{
    current_password: string;
    password: string;
    confirm_password: string;
  }>({
    extend: validator({ schema }),
    onSubmit: async ({ current_password, password }) => {
      // TODO Convert this to a base fetcher api function updatePassword, and updatePasswordAction, etc..
      const response = await fetch(`${import.meta.env.VITE_ROMA_API}/account`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          // TODO: Remove TESTER TOKEN!!
          Authorization: `Bearer ${session()?.token}`,
        },
        body: JSON.stringify({
          CurrentPassword: current_password,
          Password: password,
        }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new RomaAPIError(response, data);
      }

      return response.json();
    },
    onSuccess() {
      setFormState({
        status: "success",
        message: "Your password has been successfully reset.",
      });
    },
    onError(error) {
      let message =
        "There was an unexpected error completing this request. Please contact support for assistance.";

      if (error instanceof RomaAPIError) {
        switch (error.code) {
          case "INCORRECT_CURRENT_PASSWORD":
            setFormState({
              status: "error",
              message: "Supplied current password is incorrect.",
            });
            break;
          // TODO: other codes?
          default:
            setFormState({ status: "error", message: message });
            break;
        }
        return;
      }

      setFormState({ status: "error", message: message });
    },
  });
  form;

  const resetForm = () => {
    setFormState({ status: null, message: "" });
    reset();
  };

  return (
    <AccountPanel>

      <form use:form class="pb-20 ">
        <h2 class="text-3xl font-bold">Change Password</h2>
        <section class="flex flex-col gap-5 py-8 max-w-2xl">
          <div class="bg-roma-grey text-roma-dark-grey text-sm p-5">
            <p>
              If you would like to change your password, please enter your
              current password along with your new password and confirmation.
            </p>
          </div>
          <Show when={formState().status === "success"}>
            <div class="bg-roma-blue text-white text-sm p-5">
              <p>{formState().message}</p>
            </div>
          </Show>
          <Show when={formState().status === "error"}>
            <div class="border-2 border-red-500 text-red-500 p-3 rounded-md">
              {formState().message}
            </div>
          </Show>
          <TextFieldInput
            name="current_password"
            autocomplete="current-password"
            type="password"
            label="Current Password"
            value={data()?.current_password}
            labelClass="text-roma-dark-grey"
            icon={eyeSlash}
            disabled={isSubmitting()}
            error={errors().current_password}
            validationState={errors().current_password ? "invalid" : "valid"}
          />
          <TextFieldInput
            name="password"
            autocomplete="new-password"
            type="password"
            label="Create Password"
            value={data()?.password}
            labelClass="text-roma-dark-grey"
            disabled={isSubmitting()}
            error={errors().password}
            validationState={errors().password ? "invalid" : "valid"}
          />
          <TextFieldInput
            name="confirm_password"
            autocomplete="new-password"
            type="password"
            label="Confirm Password"
            value={data().confirm_password}
            labelClass="text-roma-dark-grey"
            disabled={isSubmitting()}
            error={errors().confirm_password}
            validationState={errors().confirm_password ? "invalid" : "valid"}
          />
          <div class="flex justify-between items-start">
            <button
              type="button"
              class="text-roma-blue text-xs mr-2"
              onClick={resetForm}
            >
              Reset Form
            </button>
            <Button class="px-8" disabled={isSubmitting()}>
              <div class="text-sm font-medium">Update Password</div>
              <Loader
                show={isSubmitting()}
                height="5"
                width="5"
                class="!mr-0 ml-2"
              />
            </Button>
          </div>
        </section>
      </form>
    </AccountPanel>
  );
};

export default Password;
